import React, { useEffect, useState } from "react";
import { ChatLeftTextFill, X } from "react-bootstrap-icons";
import {
  FloatConSeparadorPuntoYDecimalComa,
  FloatPattern,
} from "src/modules/common/helpers/helpers";

function IndicadorKPIFormularioItem({
  calificacionKpi = {},
  name = "radio-group",
  previousKpi,
  showPrevious = false,
  disabled = false,
  error = "",
  onChangeAlcance,
  onChangeCalificacion,
  onChangeComentario,
  puntuacionesCustom = [],
  mostrarPesosIndicadores = false,
  habilitarOpcionNoAplica = true,
}) {
  const { kpi, calificacion, alcance, comentarios: comment } = calificacionKpi;
  const { nombre, descripcion, periodo, metrica, meta, rangos } = kpi;
  const [hasComment, setHasComment] = useState(false);
  const [mostrarEscala, setMostrarEscala] = useState(false);
  const puntuaciones = [
    { label: "Siempre", value: 100 },
    { label: "Constantemente", value: 75 },
    { label: "Frecuentemente", value: 50 },
    { label: "En Ocasiones", value: 25 },
    { label: "Nunca", value: 0 },
    { label: "No aplica", value: -1 },
  ];
  const [puntuacionesLocal, setPuntuacionesLocal] = useState(
    puntuacionesCustom.length > 0 ? puntuacionesCustom : puntuaciones
  );
  const lastIndex = puntuacionesLocal.length - 1;
  const [alcanceLocal, setAlcanceLocal] = useState(null);
  const RadioItem = ({
    name = "",
    label = "radio label",
    checked = false,
    defaultChecked,
    onChange,
    disabled = false,
    peso = 0,
    mostrarPeso = false,
  }) => {
    return (
      <div className="flex gap-2 items-center">
        <input
          type="radio"
          className="h-5 w-5"
          name={name}
          disabled={disabled}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={!disabled ? onChange : null}
        />
        <div>{label}</div>
        {!!mostrarPeso && (
          <div className="bg-slate-300 py-1 px-2 rounded-md">{peso}%</div>
        )}
      </div>
    );
  };
  const toggleHasComment = () => {
    setHasComment(!hasComment);
  };
  const showEscalaModal = () => {
    setMostrarEscala(true);
  };
  const hideEscalaModal = () => {
    setMostrarEscala(false);
  };
  const getTotalObjetivoLabel = (kpi) => {
    const { metrica, rangos } = kpi;
    const tipoMetrica = metrica.value;
    const tieneRangos = rangos.length > 0;
    //
    const metricasNumericas = [1, 2];
    const seleccionSimple = 3;
    if (!tieneRangos) {
      if (metricasNumericas.includes(tipoMetrica))
        return kpi.meta.toLocaleString("de-DE"); // toLocaleString("de-DE") formatea números con separadores de miles. Región DE es Alemania, para separar con punto.
      if (tipoMetrica === seleccionSimple) return "Logro/No logro";
    } else {
      return (
        <button
          className="text-sky-500 font-semibold text-sm"
          onClick={showEscalaModal}
        >
          Ver escala
        </button>
      );
    }
  };
  const getTotalObjetivo = (kpi) => {
    const { metrica, rangos } = kpi;
    const tipoMetrica = metrica.value;
    const tieneRangos = rangos.length > 0;
    const tieneEscalaDinamica = kpi?.tiene_escala_dinamica;
    //
    const metricasNumericas = [1, 2];
    const seleccionSimple = 3;
    if (!tieneRangos) {
      if (metricasNumericas.includes(tipoMetrica)) return kpi.meta;
      if (tipoMetrica === seleccionSimple) return 100;
    } else {
      if (!!tieneEscalaDinamica) {
        let rangosCopy = JSON.parse(JSON.stringify(rangos));
        rangosCopy.forEach((rango) => {
          rango.esDinamico = rango.resultado === null;
        });
        return rangosCopy;
      }
      return rangos;
    }
  };
  const onChangeComentarioLocal = (e) => {
    const comentario = e.target?.value;
    onChangeComentario(comentario);
  };
  const onChangeAlcanceLocal = (totalObjetivo, customAlcance) => (e) => {
    let alcance = e.target?.value;
    if (alcance?.split(",").length > 2) {
      return;
    }
    let wasEdited = true;
    if (customAlcance !== null && customAlcance !== undefined) {
      alcance = customAlcance;
      wasEdited = false;
    }
    let cumplimiento = 0;
    if (alcance && !alcance.endsWith(",")) {
      alcance = (alcance + "").replace(/\./g, "");
      if (alcance.includes(",")) {
        const parteEntera = alcance.slice(0, alcance.indexOf(","));
        const parteDecimal = alcance.slice(alcance.indexOf(","));
        alcance = Number(parteEntera).toLocaleString("de-DE") + parteDecimal;
      } else {
        alcance = Number(alcance).toLocaleString("de-DE");
      }
    }
    if (
      !alcance ||
      FloatConSeparadorPuntoYDecimalComa.test(alcance) ||
      alcance.endsWith(",")
    ) {
      setAlcanceLocal(alcance || null);
    }
    if (alcance.endsWith(",")) {
      return;
    }
    alcance = Number(alcance.replace(/\./g, "").replace(/\,/g, ".")); // Se vuelve a poner en formato numérico
    // es rango
    if (Array.isArray(totalObjetivo) && (!!alcance || alcance === 0)) {
      let rango = totalObjetivo.find(
        (to, i) => alcance >= to.desde && alcance <= to.hasta
      );
      // comprobar si no supero limite del mayor
      if (!rango) {
        let maxRango = { resultado: 0, hasta: 0 };
        totalObjetivo.forEach((to) => {
          if (to.resultado > maxRango.resultado) {
            maxRango = to;
          }
        });
        //
        if (alcance >= maxRango.hasta && !!maxRango.hasta) {
          rango = maxRango;
        }
      }
      //
      cumplimiento = !!rango
        ? !rango.esDinamico
          ? rango.resultado
          : Number(alcance)
        : 0;
      //
    } else if (totalObjetivo > 0 && (!!alcance || alcance === 0)) {
      cumplimiento = (alcance * 100) / totalObjetivo;
      if (alcance >= totalObjetivo) {
        cumplimiento = 100;
      }
    }
    //
    alcance = !!alcance || alcance === 0 ? Number(alcance) : "";
    cumplimiento = Number(cumplimiento.toFixed(2));
    onChangeAlcance(alcance, cumplimiento);
  };
  const onCheck = (puntuacion) => () => {
    onChangeCalificacion(puntuacion.value);
  };
  const onNoAplicaClick = (e) => {
    const checked = e.target.checked;
    if (checked) {
      onChangeCalificacion(-1);
    } else {
      onChangeCalificacion(null);
    }
  };
  //
  useEffect(() => {
    if (calificacion === -1) {
      setAlcanceLocal(null);
    } else {
      const totalObjetivo = getTotalObjetivo(kpi);
      //
      if (alcance !== null && alcance !== undefined) {
        setAlcanceLocal(Number(alcance).toLocaleString("de-DE"));
      }
    }
  }, [calificacion]);
  //
  useEffect(() => {
    if (comment) setHasComment(true);
  }, [comment]);
  //
  return (
    <div className="flex flex-col gap-4 relative">
      {!!mostrarEscala && (
        <div className="border-2 bg-white rounded-md p-3 absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 w-1/3 z-20">
          <div className="flex items-center justify-between mb-2">
            <h3 className="font-bold text-lg">Escala</h3>
            <button className="p-1" onClick={hideEscalaModal}>
              <X size={24}></X>
            </button>
          </div>
          <div className="flex flex-col text-md">
            <div className="flex font-bold">
              <div className="flex-1">Desde</div>
              <div className="flex-1">Hasta</div>
              <div className="flex-1">Resultado</div>
            </div>
            {rangos.map((r, i) => (
              <div key={`rango-${i}`} className="flex text-sm">
                <div className="flex-1">{r.desde.toLocaleString("de-DE")}</div>
                <div className="flex-1">{r.hasta.toLocaleString("de-DE")}</div>
                <div className="flex-1">
                  {r.resultado
                    ? r.resultado.toLocaleString("de-DE")
                    : r.resultado}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {/*  */}
      <div className="flex justify-between md:w-2/3 border-2x">
        <div className="flex flex-col gap-2 flex-1">
          <div className="font-bold">Tiempo</div>
          <div>{periodo.label}</div>
        </div>
        <div className="flex flex-col gap-2  flex-1">
          <div className="font-bold">Tipo de metrica</div>
          <div>{metrica.label}</div>
        </div>
        <div className="flex-1"></div>
      </div>
      {/* Si NO es Por observación */}
      {/* calificacion anterior (para calibrador) */}
      {!!showPrevious && metrica.value !== 4 && (
        <div className="bg-slate-100 p-2 rounded-md">
          <div className="flex justify-between md:w-2/3 border-2x">
            <div className="flex flex-col gap-2 flex-1">
              <div className="font-bold">Alcance</div>
              {previousKpi?.calificacion !== -1 && (
                <div>
                  {metrica.value === 3 && (
                    <select
                      className="bg-slate-100 p-2 rounded-md w-2/3 appearance-none"
                      disabled={true}
                      value={previousKpi?.calificacion || ""}
                    >
                      <option value={null}>Seleccionar</option>
                      <option value="100">Logro</option>
                      <option value="0">No logro</option>
                    </select>
                  )}
                  {metrica.value !== 3 && (
                    <input
                      className="bg-slate-100 p-2 rounded-md w-2/3"
                      type="text"
                      value={previousKpi?.alcance.toLocaleString("de-DE")}
                      disabled={true}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 flex-1">
              <div className="font-bold">Total objetivo</div>
              <div>{getTotalObjetivoLabel(kpi)}</div>
            </div>
            <div className="flex flex-col gap-2 flex-1">
              <div className="font-bold">Cumplimiento</div>
              <div>
                {previousKpi?.calificacion === -1
                  ? "No aplica"
                  : previousKpi?.calificacion
                  ? previousKpi?.calificacion + "%"
                  : "0%"}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* calificacion actual */}
      {metrica.value !== 4 && (
        <div
          className={`${
            !!showPrevious
              ? "border-2 border-dotted border-[#FED074] bg-[#FEF8EA] rounded-md p-2"
              : "flex items-center justify-between"
          }`}
        >
          <div className="flex justify-between md:w-2/3 border-2x">
            <div className="flex flex-col gap-2 flex-1">
              {calificacion !== -1 && (
                <>
                  <div className="font-bold">Alcance</div>
                  <div>
                    {/* Selección Simple */}
                    {metrica.value === 3 && (
                      <select
                        className="border p-2 rounded-md w-2/3 bg-white"
                        disabled={disabled}
                        value={alcanceLocal}
                        onChange={
                          !disabled
                            ? onChangeAlcanceLocal(getTotalObjetivo(kpi))
                            : null
                        }
                      >
                        <option value="">Seleccionar</option>
                        <option value="100">Logro</option>
                        <option value="0">No logro</option>
                      </select>
                    )}
                    {/* Númerico con o sin escala */}
                    {metrica.value !== 3 && (
                      <input
                        className="border p-2 rounded-md w-2/3"
                        type="text"
                        value={alcanceLocal}
                        disabled={disabled}
                        onChange={
                          !disabled
                            ? onChangeAlcanceLocal(getTotalObjetivo(kpi))
                            : null
                        }
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col gap-2 flex-1">
              <div className="font-bold">Total objetivo</div>
              <div>{getTotalObjetivoLabel(kpi)}</div>
            </div>
            <div className="flex flex-col gap-2 flex-1">
              <div className="font-bold">Cumplimiento</div>
              <div>
                {calificacion === -1
                  ? "No aplica"
                  : calificacion
                  ? calificacion + "%"
                  : "0%"}
              </div>
            </div>
          </div>
          {/*  */}
          {habilitarOpcionNoAplica && (
            <div className="flex gap-2">
              <input
                onClick={!disabled ? onNoAplicaClick : null}
                checked={puntuacionesLocal[lastIndex].value === calificacion}
                disabled={disabled}
                type="checkbox"
                className="h-5 w-5"
                name=""
                id=""
              />
              <div>{puntuacionesLocal[lastIndex].label}</div>
            </div>
          )}
        </div>
      )}
      {/* SI es Por observación */}
      {metrica.value === 4 && (
        <div>
          <div className="font-bold text-sm mb-4">
            ¿Con qué frecuencia se observa el cumplimiento de este indicador?
          </div>
          <div className="flex flex-col gap-4">
            {/* previous radios */}
            {!!showPrevious && (
              <div className="rounded-md bg-slate-100 py-1 px-4">
                <div className="flex justify-between">
                  <div className="flex gap-4">
                    {puntuacionesLocal.map((p, idx) =>
                      idx !== lastIndex ? (
                        <RadioItem
                          key={`radio-${idx}`}
                          name={name}
                          label={p.label}
                          checked={p.value === previousKpi?.calificacion}
                          disabled={true}
                          peso={p.value}
                          mostrarPeso={mostrarPesosIndicadores}
                        ></RadioItem>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                  {habilitarOpcionNoAplica && (
                    <div className="flex gap-2">
                      <input
                        checked={
                          puntuacionesLocal[lastIndex].value ===
                          previousKpi?.calificacion
                        }
                        disabled={true}
                        type="checkbox"
                        className="h-5 w-5"
                      />
                      <div>{puntuacionesLocal[lastIndex].label}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* radios */}
            <div
              className={`${
                !!showPrevious
                  ? "border-2 border-dotted border-[#FED074] bg-[#FEF8EA] rounded-md py-1 px-4"
                  : ""
              }`}
            >
              <div className="flex justify-between">
                <div className="flex gap-4">
                  {puntuacionesLocal.map((p, idx) =>
                    idx !== lastIndex ? (
                      <RadioItem
                        key={`radio-p-${idx}`}
                        name={name}
                        label={p.label}
                        checked={p.value === calificacion}
                        onChange={!disabled ? onCheck(p) : null}
                        disabled={disabled}
                        peso={p.value}
                        mostrarPeso={mostrarPesosIndicadores}
                      ></RadioItem>
                    ) : (
                      ""
                    )
                  )}
                </div>
                {habilitarOpcionNoAplica && (
                  <div className="flex gap-2">
                    <input
                      onClick={!disabled ? onNoAplicaClick : null}
                      checked={
                        puntuacionesLocal[lastIndex].value === calificacion
                      }
                      disabled={disabled}
                      type="checkbox"
                      className="h-5 w-5"
                      name=""
                      id=""
                    />
                    <div>{puntuacionesLocal[lastIndex].label}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!!error && (
        <div className="border border-[#E3C8C6] text-[#9C4B46] bg-[#EEDEDE]-500 p-2 rounded-md md:w-1/2">
          {error}
        </div>
      )}
      {/* comentario */}
      <div>
        {!hasComment && (
          <button
            onClick={toggleHasComment}
            className="flex items-center gap-2"
          >
            <ChatLeftTextFill></ChatLeftTextFill>
            <div className="underline">Dejar comentario</div>
          </button>
        )}
        {!!hasComment && (
          <div className="flex flex-col gap-2">
            <h3 className="font-bold text-slate-500">Comentarios</h3>
            <div className="flex items-center gap-4">
              <textarea
                className="border rounded-md p-2"
                cols="30"
                rows="2"
                value={comment}
                disabled={disabled}
                onChange={!disabled ? onChangeComentarioLocal : null}
              ></textarea>
              <button onClick={toggleHasComment} className="underline">
                No dejar comentario
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default IndicadorKPIFormularioItem;
