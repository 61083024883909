import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";
import BarChart from "src/modules/common/components/BarChart";
import { Card } from "src/modules/common/components/Card";
import CircularProgressBar from "src/modules/common/components/CircularProgressBar";
import CircularProgressMediumBar from "src/modules/common/components/CircularProgressBarMedium";
import HistorialEvaluacionesHeader from "src/modules/common/components/HistorialEvaluacionesHeader";
import HistorialEvaluacionesTable from "src/modules/common/components/HistorialEvaluacionesTable";
import Input from "src/modules/common/components/Input";
import PercentageCircular from "src/modules/common/components/PercentageCircular";
import SkeletonLoaderRow from "src/modules/common/components/SkeletonLoaderRow";
import { getUserFromCookie } from "src/modules/common/handler/helpers";
import { formatDate } from "src/modules/common/helpers/helpers";
import { evaluacionActions } from "../handlers/redux";
import { ESTADOS_EVALUACION } from "../helpers/helpers";

function EvaluacionesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usuario = getUserFromCookie();
  const initDiv = useRef(null);
  //
  const evaluaciones = useSelector((state) => state.evaluacion.evaluaciones);
  const evaluaciones_loading = useSelector(
    (state) => state.evaluacion.evaluaciones_loading
  );
  //
  const colaborador = useSelector((state) => state.colaborador.colaborador);
  const colaborador_loading = useSelector(
    (state) => state.colaborador.colaborador_loading
  );
  const {
    promedio_evaluaciones = 0,
    ultima_evaluacion = {},
    promedios_evaluaciones = [],
    resumen_competencias_generales = [],
  } = colaborador;
  //
  const colaborador_evaluaciones = useSelector(
    (state) => state.colaborador.colaborador_evaluaciones
  );
  const colaborador_evaluaciones_filter = useSelector(
    (state) => state.colaborador.colaborador_evaluaciones_filter
  );
  const anhos_evaluaciones = useSelector(
    (state) => state.colaborador.anhos_evaluaciones
  );
  const { nombre: nombreEvaluacion } = colaborador_evaluaciones_filter;
  const colaborador_evaluaciones_loading = useSelector(
    (state) => state.colaborador.colaborador_evaluaciones_loading
  );
  const barCharParentRef = React.useRef();
  //
  const onChangeEvaluacionText = (event) => {
    const colaboradorId = usuario.colaborador_id;
    dispatch(
      colaboradorActions.setColaboradorEvaluacionesFilter({
        id: colaboradorId,
        nombre: event.target.value,
      })
    );
  };
  const filtrarPorAnho = (selectedOption) => {
    const anho = selectedOption;
    const colaboradorId = usuario.colaborador_id;
    //
    if (anho === "") {
      dispatch(
        colaboradorActions.setColaboradorEvaluacionesFilter({
          id: colaboradorId,
        })
      );
      return;
    }
    dispatch(
      colaboradorActions.setColaboradorEvaluacionesFilter({
        id: colaboradorId,
        anho_finalizacion: anho,
      })
    );
  };
  const getHistorialBarChartData = () => {
    let data = [];
    if (promedios_evaluaciones.length) {
      let promediosEvaluacionesCopy = [...promedios_evaluaciones];
      // Ordenar por id en orden descendente
      promediosEvaluacionesCopy = promediosEvaluacionesCopy.sort(
        (a, b) => b.id - a.id
      );
      // Tomar los primeros 6 elementos
      promediosEvaluacionesCopy = promediosEvaluacionesCopy.slice(0, 6);
      data = promediosEvaluacionesCopy.map((p, idx) => ({
        x: idx + 1,
        y: p.puntaje,
        name: p.nombre,
      }));
    }
    //
    return data;
  };
  const goToEvaluacionDetail = (id) => () => {
    navigate(`/evaluaciones/${id}`);
  };
  const getBarColor = (idx) => {
    const colors = [
      "#F7A25D", //0 7 14
      "#8085E9", //1 8 15
      "#F05C81", //2 9 16
      "#E5D254", //3 10
      "#DF3A33", //4 11
      "#69E455", //5 12
      "#373136", //6 13
    ];
    const safeIndex = idx % colors.length;
    return colors[safeIndex];
  };
  const resumenCompetenciasData = () => {
    let data = [];
    //
    if (resumen_competencias_generales.length < 1) return [];
    data = resumen_competencias_generales.map((item, idx) => ({
      x: idx + 1,
      y: item.calificacion,
      name: item.competencia,
      fill: getBarColor(idx),
    }));
    //
    return data;
  };
  const getEstadoColor = (estado) => {
    const colors = {
      [`estado-${ESTADOS_EVALUACION.EN_CURSO}`]: "bg-lime-500",
      [`estado-${ESTADOS_EVALUACION.FINALIZADO}`]: "bg-slate-400",
    };
    console.log("getEstadoColor", colors["estado-" + estado]);
    return colors["estado-" + estado];
  };
  //
  useEffect(() => {
    dispatch(evaluacionActions.getEvaluaciones());
    dispatch(colaboradorActions.colaborador(usuario.colaborador_id));
    dispatch(
      colaboradorActions.getColaboradorEvaluaciones({
        id: usuario.colaborador_id,
      })
    );
    if (initDiv.current) {
      initDiv.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  //
  return (
    <div ref={initDiv}>
      <Header
        titulo={"Evaluaciones"}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={`bg-white`}
      ></Header>
      <div className="flex flex-col gap-4 px-12 py-4">
        {/* EVALUACIONES */}
        <Card className=" gap-4 p-4 max-h-[450px] overflow-auto custom-scrollbar">
          <div className="flex flex-col gap-2 ">
            <div className="text-lg font-bold">Evaluaciones</div>
            <div className="border-b bg-zinc-400 mt-2"></div>
          </div>
          <div className="flex flex-col gap-2">
            {!evaluaciones_loading && (
              <div>
                {evaluaciones.length > 0 &&
                  evaluaciones.map((evaluacion, idx) => (
                    <div
                      key={idx}
                      className={"hover:cursor-pointer"}
                      onClick={goToEvaluacionDetail(evaluacion.id)}
                    >
                      <div className="flex flex-row py-3 items-center">
                        <div className="flex flex-col gap-2 w-5/12">
                          <div className="text-sm font-bold">
                            {evaluacion.nombre}
                          </div>
                          <div className="flex flex-col flex-grow text-zinc-700">
                            <div>{`Fecha inicio: ${formatDate(
                              evaluacion.fecha_inicio
                            )}`}</div>
                            <div>{`Fecha finalización: ${formatDate(
                              evaluacion.fecha_finalizacion
                            )}`}</div>
                          </div>
                        </div>
                        <div className="flex flex-row flex-grow gap-3">
                          {evaluacion.cantidad_evaluaciones !== "0/0" && (
                            <div className="flex flex-col gap-1 items-center">
                              <CircularProgressMediumBar
                                label={evaluacion.cantidad_evaluaciones}
                                percent={evaluacion.evaluaciones_porcentaje}
                              ></CircularProgressMediumBar>
                              <div className="text-sm text-zinc-400 ">
                                Evaluaciones
                              </div>
                            </div>
                          )}

                          {evaluacion.cantidad_calibraciones !== "0/0" && (
                            <div className="flex flex-col gap-1 items-center">
                              <CircularProgressMediumBar
                                label={evaluacion.cantidad_calibraciones}
                                percent={evaluacion.calibraciones_porcentaje}
                              ></CircularProgressMediumBar>
                              <div className="text-sm text-zinc-400 ">
                                Calibraciones
                              </div>
                            </div>
                          )}
                          {evaluacion.cantidad_devoluciones !== "0/0" && (
                            <div className="flex flex-col gap-1 items-center">
                              <CircularProgressMediumBar
                                label={evaluacion.cantidad_devoluciones}
                                percent={evaluacion.devoluciones_porcentaje}
                              ></CircularProgressMediumBar>
                              <div className="text-sm text-zinc-400 ">
                                Devoluciones
                              </div>
                            </div>
                          )}
                          {evaluacion.cantidad_comentarios !== "0/0" && (
                            <div className="flex flex-col gap-1 items-center">
                              <CircularProgressMediumBar
                                label={evaluacion.cantidad_comentarios}
                                percent={evaluacion.comentarios_porcentaje}
                              ></CircularProgressMediumBar>
                              <div className="text-sm text-zinc-400 ">
                                Comentarios
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="w-20 flex flex-row">
                          <div
                            className={`${getEstadoColor(
                              evaluacion.estado?.value
                            )} rounded px-2 py-1 text-sm text-center text-white`}
                          >
                            {evaluacion.estado?.label}
                          </div>
                        </div>
                        <div className="text-lg font-bold w-5"></div>
                      </div>
                      <div className="border-b bg-zinc-400"></div>
                    </div>
                  ))}
                {evaluaciones.length < 1 && (
                  <div className="flex justify-center w-full border rounded-md my-4">
                    <div className="p-1">
                      No existen evaluaciones para mostrar
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* Loader evaluaciones */}
            {!!evaluaciones_loading && (
              <div>
                {[1, 2, 3].map((l, i) => (
                  <SkeletonLoaderRow key={`li-${i}`}></SkeletonLoaderRow>
                ))}
              </div>
            )}
          </div>
        </Card>
        {/* ==================== RESUMEN GENERAL (SOLO JEFES Y ADMIN) ==================== */}
        {!colaborador_loading && (
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 px-12x">
            {/* Historial */}
            <div className="col-span-1 md:col-span-6 flex">
              <div className="border rounded-md p-3 w-full">
                <div className="font-bold">Historial de evaluaciones</div>
                <div className="flex items-center justify-center max-h-72">
                  <BarChart
                    width={600}
                    data={getHistorialBarChartData()}
                  ></BarChart>
                </div>
              </div>
            </div>
            {/* Última evaluación */}
            <div className="col-span-1 md:col-span-3 flex">
              <div className="border rounded-md p-3 flex-1 flex">
                <div className="flex flex-col justify-between flex-1">
                  <div className="flex flex-row justify-between items-center">
                    <div>
                      <div className="font-bold">Última evaluación</div>
                      <div className="text-xs">{`${formatDate(
                        ultima_evaluacion.fecha_ultima_evaluacion
                      )}`}</div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center h-full flex-1 border-2x">
                    <PercentageCircular
                      width={180}
                      height={180}
                      strokeWidth={9}
                      strokeColor={"red"}
                      value={ultima_evaluacion.puntaje_ultima_evaluacion || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {ultima_evaluacion.puntaje_ultima_evaluacion || 0}%
                          </div>
                          <div className="text-xs text-red-400">{`${ultima_evaluacion.clasificacion_ultima_evaluacion}`}</div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                </div>
              </div>
            </div>
            {/* Promedio desempeño */}
            <div className="col-span-1 md:col-span-3 flex">
              <div className="border rounded-md p-3 flex-1 flex">
                <div className="flex flex-col justify-between flex-1">
                  <div className="flex flex-row justify-between items-center">
                    <div>
                      <div className="font-bold">Promedio desempeño</div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center h-full flex-1">
                    <PercentageCircular
                      width={180}
                      height={180}
                      strokeWidth={9}
                      strokeColor={"blue"}
                      value={promedio_evaluaciones || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {promedio_evaluaciones || 0}%
                          </div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* RESUMEN COMPETENCIAS */}
        {!colaborador_loading && (
          <>
            <div className="text-lg font-bold">Resumen por competencias</div>
            <Card className=" gap-4 p-4">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <div className="text-lg font-bold flex-grow">
                    Última evaluación
                  </div>
                  <div className="text-lg font-bold w-2/12 text-right">
                    Puntaje Obtenido
                  </div>
                  <div className="text-lg font-bold w-5"></div>
                </div>
                <div>
                  <div className="border-b bg-zinc-400"></div>
                  <div className="flex flex-row justify-between py-3">
                    <div className="flex flex-col gap-2 flex-grow">
                      <div className="text-sm font-bold">
                        {ultima_evaluacion.nombre_ultima_evaluacion}
                      </div>
                      <div className="flex flex-col flex-grow text-zinc-700">
                        <div className="text-sm">
                          Fecha de inicio:{" "}
                          {`${formatDate(
                            ultima_evaluacion.fecha_inicio_ultima_evaluacion
                          )}`}
                        </div>
                        <div className="text-sm">
                          Fecha de finalizacion:{" "}
                          {`${formatDate(
                            ultima_evaluacion.fecha_ultima_evaluacion
                          )}`}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <div className="rounded px-2 py-1 bg-zinc-200 text-sm text-center text-black">
                        {` ${(
                          ultima_evaluacion.puntaje_ultima_evaluacion + ""
                        ).replace(".", ",")}% - ${
                          ultima_evaluacion.clasificacion_ultima_evaluacion
                        }`}
                      </div>
                    </div>
                    <div className="text-lg font-bold w-5"></div>
                  </div>
                </div>
                <div className="border-b bg-zinc-400"></div>
              </div>
              <div className="text-sm font-bold">
                Competencias: <span className="text-sky-500">Generales</span>
              </div>
              <div className="flex justify-center">
                <div className="w-full md:w-2/3x max-h-72">
                  <BarChart
                    width={600}
                    domainPadding={{ x: 40, y: [0, 0] }}
                    data={resumenCompetenciasData()}
                    usingXLabels={true}
                  ></BarChart>
                </div>
              </div>
            </Card>
          </>
        )}
        {/* loading competencias */}
        {!!colaborador_loading && (
          <div className="flex flex-col gap-4 animate-pulse">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              <div className="col-span-1 rounded-md bg-slate-300 md:col-span-6 w-full h-72"></div>
              <div className="col-span-1 rounded-md bg-slate-300 md:col-span-3 w-full h-72"></div>
              <div className="col-span-1 rounded-md bg-slate-300 md:col-span-3 w-full h-72"></div>
            </div>
            <div className="w-full h-72 bg-slate-300"></div>
          </div>
        )}
        {/* ==================== HISTORICO DE EVALUACIONES ==================== */}
        <div className="mt-2 p-3 border rounded-md max-h-[450px] overflow-auto custom-scrollbar">
          <div className="font-bold">Histórico de evaluaciones</div>
          {/* Header */}
          <HistorialEvaluacionesHeader
            searchValue={nombreEvaluacion}
            onSearch={onChangeEvaluacionText}
            selectOptions={anhos_evaluaciones}
            onSelect={filtrarPorAnho}
          ></HistorialEvaluacionesHeader>
          {/* Table */}
          <HistorialEvaluacionesTable
            evaluaciones={colaborador_evaluaciones}
            colaborador={{ id: usuario.colaborador_id }}
            loading={colaborador_evaluaciones_loading}
          ></HistorialEvaluacionesTable>
        </div>
      </div>
    </div>
  );
}

export default EvaluacionesPage;
