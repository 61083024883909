import { call, put, select, takeEvery } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken } from "src/modules/common/handler/helpers";
import { ObjectToString } from "src/utils/helpers";
import { evaluacionApi } from "./api";
import { evaluacionActions } from "./redux";
import { push } from "@lagunovsky/redux-react-router";

function* evaluacionesSaga({ payload }) {
  const token = getToken();
  //   const evaluaciones_filter = yield select(
  //     (state) => state.evaluacion.evaluaciones_filter
  //   );
  try {
    const data = yield call(evaluacionApi(token).evaluaciones, {});
    console.log("evaluaciones saga", data);
    yield put(evaluacionActions.setEvaluaciones(data.results));
  } catch (error) {
    console.log({ error });
  }
}

function* evaluacionSaga({ payload }) {
  const token = getToken();
  //   const evaluaciones_filter = yield select(
  //     (state) => state.evaluacion.evaluaciones_filter
  //   );
  try {
    const data = yield call(evaluacionApi(token).evaluacion, payload);
    console.log("evaluacion saga", data);
    yield put(evaluacionActions.setEvaluacion(data));
  } catch (error) {
    console.log({ error });
  }
}

function* evaluacionesLiderSaga({ payload }) {
  const token = getToken();
  const evaluaciones_filter = yield select(
    (state) => state.evaluacion.evaluaciones_lider_filter
  );
  const params = { ...payload, ...evaluaciones_filter };
  try {
    const data = yield call(evaluacionApi(token).evaluacionesLider, params);
    console.log("calibracionesLiderSaga saga", data);
    yield put(evaluacionActions.setEvaluacionesLider(data.results));
  } catch (error) {
    console.log({ error });
  }
}

function* calibracionesLiderSaga({ payload }) {
  const token = getToken();
  const evaluaciones_filter = yield select(
    (state) => state.evaluacion.calibraciones_lider_filter
  );
  const params = { ...payload, ...evaluaciones_filter };
  try {
    const data = yield call(evaluacionApi(token).calibracionesLider, params);
    console.log("calibracionesLiderSaga saga", data);
    yield put(evaluacionActions.setCalibracionesLider(data.results));
  } catch (error) {
    console.log({ error });
  }
}

function* devolucionesLiderSaga({ payload }) {
  const token = getToken();
  const evaluaciones_filter = yield select(
    (state) => state.evaluacion.devoluciones_lider_filter
  );
  const params = { ...payload, ...evaluaciones_filter };
  try {
    const data = yield call(evaluacionApi(token).devolucionesLider, params);
    console.log("devolucionesLiderSaga saga", data);
    yield put(evaluacionActions.setDevolucionesLider(data.results));
  } catch (error) {
    console.log({ error });
  }
}

function* comentariosLiderSaga({ payload }) {
  const token = getToken();
  const evaluaciones_filter = yield select(
    (state) => state.evaluacion.comentarios_lider_loading
  );
  const params = { ...payload, ...evaluaciones_filter };
  try {
    const data = yield call(evaluacionApi(token).comentariosLider, params);
    console.log("comentariosLiderSaga saga", data);
    yield put(evaluacionActions.setComentariosLider(data.results));
  } catch (error) {
    console.log({ error });
  }
}
//
function* formularioSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(evaluacionApi(token).formularioDetalle, payload);
    console.log("formularioSaga saga", data);
    yield put(evaluacionActions.setFormulario(data));
    yield put(evaluacionActions.setFormularioComplete());
  } catch (error) {
    console.log({ error });
    yield put(evaluacionActions.setFormularioComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: "Ocurrió un error" });
  }
}
// procesos de evaluaciones
function* evaluarSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formulario = yield select((state) => state.evaluacion.formulario);
  const params = {
    id: evaluacion.id,
    formularioId: formulario.id,
    data: payload,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).evaluar, params);
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    yield put(evaluacionActions.evaluacionComplete());
    yield put(push(`/evaluaciones/${evaluacion.id}`));
    SuccessToast({ message: "Evaluación realizada con éxito!" });
  } catch (error) {
    console.log({ error });
    yield put(evaluacionActions.evaluacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* calibrarSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formulario = yield select((state) => state.evaluacion.formulario);
  const params = {
    id: evaluacion.id,
    formularioId: formulario.id,
    data: payload,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).calibrar, params);
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    yield put(evaluacionActions.evaluacionComplete());
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    yield put(push(`/evaluaciones/${evaluacion.id}`));
    SuccessToast({ message: "Calibración realizada con éxito!" });
  } catch (error) {
    console.log({ error });
    yield put(evaluacionActions.evaluacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* devolverSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formulario = yield select((state) => state.evaluacion.formulario);
  const params = {
    id: evaluacion.id,
    formularioId: formulario.id,
    data: payload,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).devolver, params);
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    yield put(evaluacionActions.evaluacionComplete());
    yield put(push(`/evaluaciones/${evaluacion.id}`));
    SuccessToast({ message: "Devolución realizada con éxito!" });
  } catch (error) {
    console.log({ error });
    yield put(evaluacionActions.evaluacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* comentarSaga({ payload }) {
  const token = getToken();
  const evaluacion = yield select((state) => state.evaluacion.evaluacion);
  const formulario = yield select((state) => state.evaluacion.formulario);
  const params = {
    id: evaluacion.id,
    formularioId: formulario.id,
    data: payload,
  };
  //
  try {
    const data = yield call(evaluacionApi(token).comentar, params);
    yield put(
      evaluacionActions.getFormulario({
        id: evaluacion.id,
        formularioId: formulario.id,
      })
    );
    yield put(evaluacionActions.evaluacionComplete());
    yield put(push(`/evaluaciones/${evaluacion.id}`));
    SuccessToast({ message: "Comentario realizado con éxito!" });
  } catch (error) {
    console.log({ error });
    yield put(evaluacionActions.evaluacionComplete());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* InformeColaboradorSaga({ payload }) {
  const token = getToken();
  const params = payload;
  //
  try {
    if (!payload.id) {
      return;
    }
    const data = yield call(evaluacionApi(token).informeColaborador, params);
    console.log("InformeColaboradorSaga data", data);
    yield put(evaluacionActions.setInformeColaborador(data));
  } catch (error) {
    console.log({ error });
    yield put(evaluacionActions.informeColaboradorError());
    const errores = error.response.data;
    const message = ObjectToString(errores);
    ErrorToast({ message: "Ocurrió un error" });
  }
}

function* getNineboxDataDetailSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      evaluacionApi(token).getNineboxDataDetail,
      payload
    );
    yield put(evaluacionActions.setNineboxDataDetail(data));
  } catch (error) {
    console.log(error);
    yield put(evaluacionActions.setNineboxDataDetail([]));
    ErrorToast({
      message: "Error al obtener datos para el Nine Box.",
    });
  }
}

function* getNineboxDataIndividualSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      evaluacionApi(token).getNineboxDataIndividual,
      payload
    );
    yield put(evaluacionActions.setNineboxDataIndividual(data));
  } catch (error) {
    console.log(error);
    yield put(evaluacionActions.setNineboxDataIndividual([]));
    ErrorToast({
      message: "Error al obtener datos para el Ninebox del colaborador.",
    });
  }
}

export function* evaluacionSagas() {
  yield takeEvery(evaluacionActions.getEvaluaciones.type, evaluacionesSaga);
  yield takeEvery(evaluacionActions.getEvaluacion.type, evaluacionSaga);
  //
  yield takeEvery(
    evaluacionActions.getEvaluacionesLider.type,
    evaluacionesLiderSaga
  );
  yield takeEvery(
    evaluacionActions.setEvaluacionesLiderFilter.type,
    evaluacionesLiderSaga
  );
  //
  yield takeEvery(
    evaluacionActions.getCalibracionesLider.type,
    calibracionesLiderSaga
  );
  yield takeEvery(
    evaluacionActions.setCalibracionesLiderFilter.type,
    calibracionesLiderSaga
  );
  //
  yield takeEvery(
    evaluacionActions.getDevolucionesLider.type,
    devolucionesLiderSaga
  );
  yield takeEvery(
    evaluacionActions.setDevolucionesLiderFilter.type,
    devolucionesLiderSaga
  );
  //
  yield takeEvery(
    evaluacionActions.getComentariosLider.type,
    comentariosLiderSaga
  );
  yield takeEvery(
    evaluacionActions.setComentariosLiderFilter.type,
    comentariosLiderSaga
  );
  //
  yield takeEvery(evaluacionActions.getFormulario.type, formularioSaga);
  //
  yield takeEvery(evaluacionActions.evaluar.type, evaluarSaga);
  yield takeEvery(evaluacionActions.calibrar.type, calibrarSaga);
  yield takeEvery(evaluacionActions.devolver.type, devolverSaga);
  yield takeEvery(evaluacionActions.comentar.type, comentarSaga);
  //
  yield takeEvery(
    evaluacionActions.getInformeColaborador.type,
    InformeColaboradorSaga
  );
  //
  yield takeEvery(
    evaluacionActions.getNineboxDataDetail.type,
    getNineboxDataDetailSaga
  );
  yield takeEvery(
    evaluacionActions.getNineboxDataIndividual.type,
    getNineboxDataIndividualSaga
  );
}
