import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import FormularioCalificaciones from "../components/FormularioCalificaciones";
import { evaluacionActions } from "../handlers/redux";
import { getPuntuacionesCustom } from "../helpers/helpers";

function FormularioFinalizadoPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { idEvaluacion, idFormulario } = params;
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const formulario = useSelector((state) => state.evaluacion.formulario);
  const formulario_loading = useSelector(
    (state) => state.evaluacion.formulario_loading
  );
  //
  const getUltimaEtapa = () => {
    const { tiene_devolucion, tiene_comentarios_evaluado } = evaluacion;
    const { calibrador } = formulario;
    let ultimaEtapa = 1;
    //
    if (!calibrador) {
      return ultimaEtapa;
    }
    //
    if (tiene_devolucion) {
      ultimaEtapa = 3;
    } else if (tiene_comentarios_evaluado) {
      ultimaEtapa = 2;
    }
    //
    return ultimaEtapa;
  };
  const etapa = getUltimaEtapa();

  const getInputComentarios = () => {
    const {
      tiene_calibracion_superior,
      tiene_devolucion,
      tiene_comentarios_evaluado,
    } = evaluacion;
    const { calibrador, relacion } = formulario;
    let inputComentarios = {
      comentarios_evaluador: { show: true, editable: false },
      comentarios_calibrador: {
        show: tiene_calibracion_superior && !!calibrador,
        editable: false,
      },
      plan_accion: { show: tiene_devolucion, editable: false },
      comentarios_evaluado: {
        show: tiene_comentarios_evaluado,
        editable: false,
      },
    };
    //
    if (relacion !== "Jefe directo") {
      inputComentarios.plan_accion.show = false;
      inputComentarios.comentarios_evaluado.show = false;
    }
    return inputComentarios;
  };
  //
  useEffect(() => {
    console.log("FEFin useEffect ");
    dispatch(
      evaluacionActions.getFormulario({
        id: idEvaluacion,
        formularioId: idFormulario,
      })
    );
  }, []);
  //
  return (
    <div className="flex flex-col gap-4 mt-4 px-8">
      <div>
        <div className="border-b pb-3">
          <span className="font-bold text-sky-500 text-xl">Etapa:</span>{" "}
          <span className="text-xl">Finalizado</span>
        </div>
      </div>
      {/*  */}
      {!formulario_loading && (
        <>
          {Object.keys(formulario).length > 0 && (
            <FormularioCalificaciones
              formulario={formulario}
              etapa={etapa}
              inputComentarios={getInputComentarios()}
              disabledFields={["indicadores"]}
              verAnotaciones={false}
              esEditable={false}
              puntuacionesCustom={getPuntuacionesCustom(evaluacion)}
              mostrarPesosIndicadores={
                evaluacion?.visualizar_pesos_indicadores_observacion
              }
              showSubmitButton={false}
              habilitarOpcionNoAplica={
                evaluacion.tipo_evaluacion !== 2
              } /* Para evaluaciones de potencial se debe deshabilitar la opción de No aplica en los formularios */
              mostrarNinebox={evaluacion.tipo_evaluacion === 2}
            ></FormularioCalificaciones>
          )}
          {Object.keys(formulario).length < 1 && (
            <div className="flex justify-center border rounded-md p-2">
              No existe formulario
            </div>
          )}
        </>
      )}
      {!!formulario_loading && (
        <div className="flex justify-center p-2">
          <LoadingSpinButton
            message={"Cargando formulario, por favor espere"}
          ></LoadingSpinButton>
        </div>
      )}
    </div>
  );
}
export default FormularioFinalizadoPage;
