import { push } from "@lagunovsky/redux-react-router";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { getToken } from "src/modules/common/handler/helpers";
import { inicioActions } from "src/modules/inicio/handlers/redux";
import { colaboradorApi } from "./api";
import { colaboradorActions } from "./redux";
import { getAnhosEvaluaciones } from "../helper/helpers";

function* colaboradorSaga({ payload }) {
  console.info("colaboradorSaga", payload);
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).colaborador, { id: payload });
    yield put(colaboradorActions.setColaborador(data));
    console.info("colaboradorSaga data", data);
  } catch (error) {
    console.log({ error });
  }
}

function* colaboradoresSaga({ payload }) {
  const token = getToken();
  const colaboradoresFilter = yield select(
    (state) => state.colaborador.colaboradores_filter
  );
  try {
    const data = yield call(colaboradorApi(token).list, {
      colaboradores_filter: colaboradoresFilter,
    });
    yield put(colaboradorActions.setColaboradores(data));
    console.info("colaboradorSaga data", data);
  } catch (error) {
    console.log({ error });
  }
}

function* nuevosIngresosSaga({ payload }) {
  const token = getToken();
  //
  try {
    const data = yield call(colaboradorApi(token).nuevosIngresos);
    yield put(colaboradorActions.setNuevosIngresos(data));
  } catch (error) {
    console.log({ error });
  }
}

function* areasSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).areas);
    yield put(colaboradorActions.setAreas(data));
  } catch (error) {
    console.log({ error });
  }
}

function* sucursalesSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).sucursales);
    yield put(colaboradorActions.setSucursales(data));
  } catch (error) {
    console.log({ error });
  }
}

function* departamentosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).departamentos);
    yield put(colaboradorActions.setDepartamentos(data));
  } catch (error) {
    console.log({ error });
  }
}

function* colaboradorUpdateSaga({ payload }) {
  const token = getToken();
  const colaborador = yield select((state) => state.colaborador.colaborador);
  const params = { ...colaborador };
  if (!(params.nombre && params.apellido && params.ci && params.email)) {
    // ErrorToast({ message: "Faltan campos obligatorios" });
    console.error("Error saga");
    return;
  }
  console.info(
    "params.foto_empleado",
    typeof params.foto_empleado,
    params.foto_empleado
  );
  if (typeof params.foto_empleado === "string") {
    params.foto_empleado = null;
  }
  //
  try {
    const data = yield call(colaboradorApi(token).update, params);
    //
    yield put(colaboradorActions.colaborador(colaborador.id));
    yield put(push("/mi-perfil"));
    yield put(inicioActions.inicios({}));
    yield put(colaboradorActions.colaboradorUpdateSuccess());
    SuccessToast({ message: "Imagen actualizada con éxito!" });
  } catch (error) {
    yield put(colaboradorActions.colaboradorUpdateSuccess());
    ErrorToast({ message: "Ocurrió un error." });
    console.log({ error });
  }
}

function* colaboradorEvaluacionesSaga({ payload }) {
  const token = getToken();
  const colaborador_evaluaciones_filter = yield select(
    (state) => state.colaborador.colaborador_evaluaciones_filter
  );
  const params = {
    id: payload.id,
    colaborador_evaluaciones_filter: colaborador_evaluaciones_filter,
  };
  //
  try {
    const data = yield call(colaboradorApi(token).getEvaluaciones, params);
    yield put(colaboradorActions.setColaboradorEvaluaciones(data.results));
    if (!colaborador_evaluaciones_filter.anho_finalizacion) {
      yield put(
        colaboradorActions.setAnhosEvaluaciones(
          getAnhosEvaluaciones(data.results)
        )
      );
    }
    yield put(colaboradorActions.setColaboradorEvaluacionesComplete());
  } catch (error) {
    console.log({ error });
    yield put(colaboradorActions.setColaboradorEvaluacionesComplete());
  }
}

function* getEmpresaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(colaboradorApi(token).getEmpresa);
    yield put(colaboradorActions.setEmpresa(data));
    yield put(colaboradorActions.setEmpresaComplete());
  } catch (error) {
    console.log({ error });
    yield put(colaboradorActions.setEmpresaComplete());
    ErrorToast({ message: "Ocurrió un error." });
  }
}

export function* colaboradorSagas() {
  yield takeEvery(colaboradorActions.getEmpresa.type, getEmpresaSaga);
  yield takeEvery(
    colaboradorActions.getNuevosIngresos.type,
    nuevosIngresosSaga
  );
  yield takeEvery(colaboradorActions.colaboradores.type, colaboradoresSaga);
  yield takeEvery(colaboradorActions.colaborador.type, colaboradorSaga);
  yield takeLatest(
    colaboradorActions.setColaboradoresFilter.type,
    colaboradoresSaga
  );
  yield takeEvery(
    colaboradorActions.colaboradorUpdate.type,
    colaboradorUpdateSaga
  );
  yield takeEvery(colaboradorActions.areas.type, areasSaga);
  yield takeEvery(colaboradorActions.sucursales.type, sucursalesSaga);
  yield takeEvery(colaboradorActions.departamentos.type, departamentosSaga);
  //
  yield takeEvery(
    colaboradorActions.getColaboradorEvaluaciones.type,
    colaboradorEvaluacionesSaga
  );
  yield takeLatest(
    colaboradorActions.setColaboradorEvaluacionesFilter.type,
    colaboradorEvaluacionesSaga
  );
}
