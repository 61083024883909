import { createSlice } from "@reduxjs/toolkit";

const evaluacionSlice = createSlice({
  name: "Evaluacion",
  initialState: {
    evaluaciones: [],
    evaluaciones_loading: false,
    //
    evaluacion: {},
    evaluacion_loading: false,
    //
    evaluaciones_lider: [],
    evaluaciones_lider_filter: {},
    evaluaciones_lider_loading: false,
    //
    calibraciones_lider: [],
    calibraciones_lider_filter: {},
    calibraciones_lider_loading: false,
    //
    devoluciones_lider: [],
    devoluciones_lider_filter: {},
    devoluciones_lider_loading: false,
    //
    comentarios_lider: [],
    comentarios_lider_filter: {},
    comentarios_lider_loading: false,
    //
    formulario: {},
    formulario_loading: false,
    //
    informe_colaborador: {},
    informe_colaborador_loading: false,
    //
    ninebox_data: [],
    ninebox_data_loading: false,
    ninebox_data_individual: [],
    ninebox_data_individual_loading: false,
  },
  reducers: {
    getEvaluaciones: (state) => {
      state.evaluaciones_loading = true;
    },
    setEvaluaciones: (state, { payload = {} }) => {
      state.evaluaciones = payload;
      state.evaluaciones_loading = false;
    },
    getEvaluacion: (state, { payload = {} }) => {
      state.evaluacion_loading = true;
    },
    setEvaluacion: (state, { payload = {} }) => {
      state.evaluacion = payload;
      state.evaluacion_loading = false;
    },
    //
    getEvaluacionesLider: (state, { payload = {} }) => {
      state.evaluaciones_lider_loading = true;
    },
    setEvaluacionesLider: (state, { payload = {} }) => {
      state.evaluaciones_lider = payload;
      state.evaluaciones_lider_loading = false;
    },
    setEvaluacionesLiderFilter: (state, { payload = {} }) => {
      state.evaluaciones_lider_filter = payload;
    },
    //
    getCalibracionesLider: (state, { payload = {} }) => {
      state.calibraciones_lider_loading = true;
    },
    setCalibracionesLider: (state, { payload = {} }) => {
      state.calibraciones_lider = payload;
      state.calibraciones_lider_loading = false;
    },
    setCalibracionesLiderFilter: (state, { payload = {} }) => {
      state.calibraciones_lider_filter = payload;
    },
    //
    getDevolucionesLider: (state, { payload = {} }) => {
      state.devoluciones_lider_loading = true;
    },
    setDevolucionesLider: (state, { payload = {} }) => {
      state.devoluciones_lider = payload;
      state.devoluciones_lider_loading = false;
    },
    setDevolucionesLiderFilter: (state, { payload = {} }) => {
      state.devoluciones_lider_filter = payload;
    },
    //
    getComentariosLider: (state, { payload = {} }) => {
      state.comentarios_lider_loading = true;
    },
    setComentariosLider: (state, { payload = {} }) => {
      state.comentarios_lider = payload;
      state.comentarios_lider_loading = false;
    },
    setComentariosLiderFilter: (state, { payload = {} }) => {
      state.comentarios_lider_filter = payload;
    },
    //
    getFormulario: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    setFormulario: (state, { payload = {} }) => {
      state.formulario = payload;
    },
    setFormularioComplete: (state) => {
      state.formulario_loading = false;
    },
    //
    evaluar: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    calibrar: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    devolver: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    comentar: (state, { payload = {} }) => {
      state.formulario_loading = true;
    },
    evaluacionComplete: (state) => {
      state.formulario_loading = false;
    },
    //
    getInformeColaborador: (state, { payload = {} }) => {
      state.informe_colaborador_loading = true;
    },
    setInformeColaborador: (state, { payload = {} }) => {
      state.informe_colaborador = payload;
      state.informe_colaborador_loading = false;
    },
    informeColaboradorError: (state, { payload = {} }) => {
      state.informe_colaborador_loading = false;
    },
    // Ninebox
    getNineboxDataDetail: (state, { payload = {} }) => {
      state.ninebox_data_loading = true;
    },
    setNineboxDataDetail: (state, { payload = {} }) => {
      state.ninebox_data = payload;
      state.ninebox_data_loading = false;
    },
    getNineboxDataIndividual: (state, { payload = {} }) => {
      state.ninebox_data_individual_loading = true;
    },
    setNineboxDataIndividual: (state, { payload = {} }) => {
      state.ninebox_data_individual = payload;
      state.ninebox_data_individual_loading = false;
    },
  },
});

export const evaluacionActions = evaluacionSlice.actions;
export const evaluacionReducer = evaluacionSlice.reducer;
