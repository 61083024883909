import axios from "axios";

export function DescargarColaboradoresKpisInforme(
  token = "",
  onComplete = null
) {
  const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";
  const requestURL = URL_BASE + `/kpi/descargar-listado-colaboradores`;
  const headers = {
    "Content-Type": "blob",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  axios({
    url: requestURL,
    method: "GET",
    headers: headers,
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `KPIs de colaboradores - ${new Date().toLocaleString()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      //
      if (!!onComplete) {
        onComplete();
      }
    })
    .catch((e) => {
      console.info("Error al descargar el archivo");
      if (!!onComplete) {
        onComplete();
      }
    });
}
