import React, { useEffect, useState } from "react";
import { ClockHistory, FiletypeXls, XLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "src/modules/app/components/Header";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";
import { BuscarInput } from "src/modules/common/components/BuscarInput";
import { Card } from "src/modules/common/components/Card";
import Modal from "src/modules/common/components/Modal";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import { WarningToast } from "src/modules/common/components/WarningToast";
import {
  getToken,
  getUserFromCookie,
} from "src/modules/common/handler/helpers";
import { KpiInfo } from "src/modules/kpi/components/KpiInfo";
import { DescargarColaboradoresKpisInforme } from "src/modules/kpi/handlers/helpers";
import { objetivoCargoActions } from "../handlers/redux";
import ObjetivoCargoHistorial from "../components/ObjetivoCargoHistorial";
import { DescargarColaboradoresObjetivosCargoInforme } from "../helpers/helper";

/*
  Se reutilizan varios componentes que se utilizan para KPI, como KPIHistorial, KpiInfo
*/

export function ObjetivoCargoPage() {
  const token = getToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const usuario = getUserFromCookie();
  const colaboradorId = usuario.colaborador_id;
  const empresa = useSelector((state) => state.colaborador.empresa);
  const { habilitacion_edicion_propia_objetivos_cargo = false } = empresa; //si está habilitados para kpis tambien para objetivos por cargo
  //
  const [nameFilter, setNameFilter] = useState("");
  const [areaId, setAreaId] = useState("");
  const [sucursalId, setSucursalId] = useState("");
  const [departamentoId, setDepartamentoId] = useState("");
  const URL_BASE = process.env.REACT_APP_URL || "http://localhost:8000";
  const objetivosAsignados =
    useSelector((state) => state.objetivoCargo.objetivosCargo) || [];
  const subordinados =
    useSelector((state) => state.objetivoCargo.subordinados) || [];
  const subordinados_filter =
    useSelector((state) => state.objetivoCargo?.subordinados_filter) || [];
  const { nombre = "" } = subordinados_filter;
  const areas = useSelector((state) => state.colaborador?.areas) || [];
  const sucursales =
    useSelector((state) => state.colaborador?.sucursales) || [];
  const departamentos =
    useSelector((state) => state.colaborador?.departamentos) || [];
  const inicioData = useSelector((state) => state.inicio.inicios) || {};
  //
  const [objetivoHistory, setObjetivoHistory] = useState([]);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const onVerHistorial = (objetivo) => () => {
    setObjetivoHistory(objetivo);
    setShowHistoryModal(true);
  };
  const onCloseHistoryModal = () => {
    setObjetivoHistory([]);
    setShowHistoryModal(false);
  };

  useEffect(() => {
    dispatch(objetivoCargoActions.getObjetivosCargo());
    dispatch(objetivoCargoActions.getSubordinados());
    //
    dispatch(colaboradorActions.areas());
    dispatch(colaboradorActions.sucursales());
    dispatch(colaboradorActions.departamentos());
    dispatch(colaboradorActions.getEmpresa());
  }, []);

  useEffect(() => {
    dispatch(
      objetivoCargoActions.setSubordinadosFilter({
        nombre: nameFilter,
        areaId: areaId,
        sucursalId: sucursalId,
        departamentoId: departamentoId,
      })
    );
  }, [nameFilter, areaId, sucursalId, departamentoId]);

  const onChangeText = (event) => {
    setNameFilter(event.target.value);
  };

  const onRowClick = (id) => {
    navigate(`/objetivo-cargo/colaborador/${id}`);
  };

  const [descargarInformeLoading, setDescargarInformeLoading] = useState(false);
  const onFinishDescargarLoading = () => {
    setDescargarInformeLoading(false);
  };
  const onDescargarInforme = () => {
    setDescargarInformeLoading(true);
    DescargarColaboradoresObjetivosCargoInforme(token, onFinishDescargarLoading);
  };

  return (
    <>
      <Header
        titulo={"Objetivos por cargo"}
        subtitulo={"Este es el resumen del perfil seleccionado"}
        className={`bg-white border-b-2`}
      ></Header>

      <div className="flex flex-col gap-4 px-12 py-4">
        <Card bgColor="bg-zinc-100" className="gap-4 p-4 bg-blue-4">
          <div className="flex items-center justify-between gap-3">
            <div className="flex flex-row gap-2  items-center">
              {!inicioData.lider?.foto_empleado && (
                <div className="w-12 h-12 bg-zinc-200 rounded-md"></div>
              )}
              {!!inicioData.lider?.foto_empleado && (
                <div className="w-312 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-12 w-12 rounded-md"
                    src={inicioData.lider?.foto_empleado}
                    alt={`Foto Lider`}
                  />
                </div>
              )}
              <div className="flex flex-col flex-grow text-zinc-700">
                <div className="text-sm font-bold">
                  Objetivos por cargo asignados
                </div>
                <div className="text-sm">
                  {objetivosAsignados.length} objetivos por cargo vinculados
                </div>
              </div>
            </div>
            <div>
              {!!habilitacion_edicion_propia_objetivos_cargo && (
                <button
                  className="bg-sky-500 text-white px-4 py-2 rounded-md"
                  onClick={() =>
                    navigate(`/objetivo-cargo/colaborador/${colaboradorId}`)
                  }
                >
                  Editar objetivos
                </button>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3">
            {objetivosAsignados.length > 0 &&
              objetivosAsignados.map((objetivo, idx) => (
                <Card className=" gap-3 p-4" key={`kpia-${idx}`}>
                  <KpiInfo kpi={objetivo}></KpiInfo>
                  <div className="flex mt-4 gap-2">
                    <button
                      onClick={onVerHistorial(objetivo)}
                      className="text-sm text-sky-500 flex item-center gap-1"
                    >
                      <ClockHistory className="mt-1" />
                      <span>Ver historial</span>
                    </button>
                  </div>
                </Card>
              ))}
          </div>
        </Card>

        <Card className=" gap-4 p-4">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="text-lg font-bold">
                Objetivos por cargo de mi equipo
              </div>
              {/*  */}
              <div>
                {!descargarInformeLoading && (
                  <button
                    onClick={onDescargarInforme}
                    className="flex items-center gap-2 bg-sky-500 text-white p-2 rounded"
                  >
                    <FiletypeXls size={21} />
                    <span className="font-semibold text-sm">
                      Descargar reporte
                    </span>
                  </button>
                )}
                {!!descargarInformeLoading && (
                  <button
                    disabled={true}
                    className="flex items-center gap-2 bg-sky-400 text-white px-3 py-2 rounded animated-pulse"
                  >
                    <span className="font-semibold text-sm">
                      Descargando...
                    </span>
                  </button>
                )}
              </div>
            </div>

            <div className="flex flex-row gap-3">
              <BuscarInput onChange={onChangeText} value={nombre}></BuscarInput>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-3">
              <div className="text-lg flex-grow font-bold">
                Lista de integrantes
              </div>
              <div className="text-lg font-bold w-44">#Objetivos</div>
              <div className="w-5"></div>
            </div>
            <div>
              <div className="border-b bg-zinc-400"></div>
              {subordinados.length > 0 &&
                subordinados.map((subordinado, idx) => (
                  <div
                    key={idx}
                    onClick={() => onRowClick(subordinado.id)}
                    className="hover:cursor-pointer"
                  >
                    <div className="flex flex-row items-center py-3 gap-3">
                      <div className="flex flex-row gap-2  items-center flex-grow">
                        {!subordinado.foto_empleado && (
                          <ProfileImage className="h-14 w-14 p-2"></ProfileImage>
                        )}
                        {!!subordinado.foto_empleado && (
                          <div className="w-14 bg-slate-200 rounded-md">
                            <img
                              className="object-cover h-14 w-14 rounded-md"
                              src={subordinado.foto_empleado}
                              alt={`Foto`}
                            />
                          </div>
                        )}
                        <div className="flex flex-col flex-grow text-zinc-700">
                          <div className="text-sm font-bold">
                            {`${subordinado.nombre} ${subordinado.apellido}`}
                          </div>
                          <div className="text-sm">
                            Cargo:{" "}
                            {subordinado.cargo?.nombre || "Sin especificar"}
                          </div>
                        </div>
                      </div>

                      <div className="w-44 flex flex-row">
                        <div className="px-2 py-1 text-sm text-white bg-red-500 rounded-md">
                          {subordinado.cantidad_objetivos || 0}
                        </div>
                      </div>
                      <div className="w-5"></div>
                    </div>
                    <div className="border-b bg-zinc-400"></div>
                  </div>
                ))}
              {subordinados.length < 1 && (
                <div className="w-100 text-center mt-4">
                  No existen resultados para la búsqueda.
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
      <Modal isOpen={showHistoryModal}>
        <div className="rounded-md bg-white p-4">
          <div className="w-100 flex justify-between mb-4">
            <div className="font-semibold text-xl">Historial de objetivos</div>
            <div onClick={onCloseHistoryModal}>
              <XLg />
            </div>
          </div>
          {/*  */}
          <ObjetivoCargoHistorial {...objetivoHistory} />
        </div>
      </Modal>
    </>
  );
}
