import axios from "axios";
import { useEffect, useState } from "react";
import {
  ArrowLeftShort,
  FileEarmarkArrowDownFill,
  FileEarmarkPptFill,
} from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getToken,
  getUserFromCookie,
} from "src/modules/common/handler/helpers";
import DatosPuntajes from "../components/DatosPuntajes";
import { EvaluacionTips } from "../components/EvaluacionTips";
import { evaluacionActions } from "../handlers/redux";
import { FormularioRoutes } from "../handlers/routes";
import { puntuacionesDefault } from "../helpers/constants";

function FormularioPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const usuario = getUserFromCookie();

  const { pathname } = location;
  const { idEvaluacion, idFormulario } = params;

  const select_modal = useSelector((state) => state.common.select_modal);
  const evaluacion = useSelector((state) => state.evaluacion.evaluacion);
  const formulario = useSelector((state) => state.evaluacion.formulario);
  const formulario_loading = useSelector((state) => state.evaluacion.formulario_loading);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const getAvailableSteps = () => {
    const {
      tiene_calibracion_superior,
      tiene_devolucion,
      tiene_comentarios_evaluado,
    } = evaluacion;
    const { calibrador } = formulario;
    let stepsCopy = [];
    console.log("getAvailableSteps 1", formulario);
    console.log("getAvailableSteps 2", formulario.relacion);
    // TODO revisar, sigue tirando solo evaluacion y finalizado la 1ra vez
    if (!formulario.relacion) {
      return [];
    }
    //
    if (formulario.relacion !== "Jefe directo") {
      stepsCopy.push("evaluacion");
      stepsCopy.push("finalizado");
    } else {
      const { nombre, apellido, colaborador_id } = formulario.evaluado;
      // const nombreEvaluado = `${nombre} ${apellido}`;
      const usuarioColaboradorId = Number(usuario?.colaborador_id);
      // const esEvaluado = usuario.nombre_completo === nombreEvaluado;
      const esEvaluado = usuarioColaboradorId === colaborador_id;
      //
      if (esEvaluado) {
        stepsCopy = [];
        if (tiene_comentarios_evaluado) {
          stepsCopy.push("comentario");
        }
        stepsCopy.push("finalizado");
      } else {
        stepsCopy.push("evaluacion");
        // si es Evaluador o Calibrador puede ver todos los pasos disponibles
        if (tiene_calibracion_superior && !!calibrador) {
          stepsCopy.push("calibracion");
        }
        if (tiene_devolucion) {
          stepsCopy.push("devolucion");
        }
        if (tiene_comentarios_evaluado) {
          stepsCopy.push("comentario");
        }
        stepsCopy.push("finalizado");
      }
    }
    console.log("stepsCopy", stepsCopy);
    return stepsCopy;
  };

  const currentStep = pathname.split("/")[5] || "";
  const currenStepIndex = getAvailableSteps().indexOf(currentStep);

  const generateLinks = () => {
    let links = [];
    let stepsCopy = getAvailableSteps();
    //
    const baseURL = `/evaluaciones/${idEvaluacion}/formulario/${idFormulario}/`;
    links = stepsCopy.map((s, i) => ({
      to: baseURL + s,
      name: s,
      label: s.replace("cion", "ción"),
    }));
    return links;
  };
  const getPasosHabilitados = () => {
    const estado = formulario?.estado?.value;
    const pasosDisponibles = getAvailableSteps();

    //enviado 1 - evaluacion 0
    //evaluado 2 - evaluacion, calibracion 01
    //calibrado 3- evaluacion, calibracion, devolucion 012
    //comentado 5 - evaluacion, calibracion, comentario 0123

    //enviado 1 - evaluacion 0
    //evaluado 2 - evaluacion, calibracion 01
    //calibrado 3- evaluacion, calibracion, devolucion 012
    //devueto 4- evaluacion, calibracion, devolucion, comentario 0123
    //comentado 5 - evaluacion, calibracion, devolucion, comentario 01234
    const pasosHabilitados = pasosDisponibles.filter((s, si) => si < estado);
    return pasosHabilitados;
  };
  const navLinkClickHandler = (stepIndex) => (e) => {
    const selectedStep = getAvailableSteps()[stepIndex];
    const pasosHabilitados = getPasosHabilitados();
    if (!pasosHabilitados.includes(selectedStep)) {
      e.preventDefault();
    }
  };
  const getMostrarPuntajeCalibracion = () => {
    return currentStep === "calibracion";
  };
  const getPuntajeEvaluadorKey = () => {
    let getPuntajeEvaluadorKey = "";
    const stepsKeys = {
      evaluacion: "puntaje_evaluacion",
      calibracion: "puntaje_evaluacion",
      devolucion: "puntaje_devolucion",
      comentario: "puntaje_obtenido",
      finalizado: "puntaje_obtenido",
    };
    getPuntajeEvaluadorKey = stepsKeys[currentStep]
      ? stepsKeys[currentStep]
      : "puntaje_obtenido";
    return getPuntajeEvaluadorKey;
  };
  const onBack = () => {
    navigate(`/evaluaciones/${idEvaluacion}`);
  };
  const downloadPDF = () => {
    const token = getToken();
    const URL_BASE =
      process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
    setDownloadLoading(true);
    axios({
      url:
        URL_BASE +
        `/evaluacion/${evaluacion.id}/formulario/${formulario.id}/exportar/${currentStep}`,
      method: "POST",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `formulario-${currentStep}-${evaluacion.id}-${formulario.id}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo PDF:", error);
        setDownloadLoading(false);
      });
  };
  //
  useEffect(() => {
    if (Object.keys(evaluacion).length < 1)
      dispatch(evaluacionActions.getEvaluacion({ id: idEvaluacion }));
    //
    dispatch(
      evaluacionActions.getFormulario({
        id: idEvaluacion,
        formularioId: idFormulario,
      })
    );
    dispatch(evaluacionActions.getNineboxDataDetail({ evaluacion_id: idEvaluacion }));
  }, []);

  useEffect(() => {
    const pasosDisponibles = getAvailableSteps();
    if (pasosDisponibles.length > 0) {
      //console.log("currentStep", currentStep);
      if (!pasosDisponibles.includes(currentStep)) {
        let currentIndex = pasosDisponibles.indexOf(currentStep);
        //console.log("currentIndex", currentIndex);
        if (currentIndex < 0) {
          currentIndex = 0;
        }
        const firstValidUrl = `/evaluaciones/${idEvaluacion}/formulario/${idFormulario}/${pasosDisponibles[currentIndex]}`;
        //console.log("firstValidUrl", firstValidUrl);
        navigate(firstValidUrl);
      }
    }
  }, [formulario.id]);
  //
  return (
    <div className="">
      {/* HEADER */}
      <div
        className={`flex justify-between border-b p-4 bg-white z-30 ${!select_modal.open ? "sticky" : ""
          } top-0`}
      >
        <div className="flex items-center gap-2 borderx flex-1">
          <button
            onClick={onBack}
            className="flex items-center p-1 bg-sky-200 rounded-sm"
          >
            <ArrowLeftShort size={32} />
          </button>
          <div className="flex flex-col justify-center gap-0">
            <h1 className="font-bold text-lg m-0">Formulario de evaluación</h1>
            <p className="text-slate-500 text-sm m-0">
              Este es el resumen de evaluación del colaborador seleccionado
            </p>
          </div>
        </div>
        {!formulario_loading && (
          <div className="flex justify-between gap-4x border-4x flex-1">
            {generateLinks().map((link, idx) => (
              <div
                key={`div-f-link-${idx}`}
                className={`borderx flex flex-col flex-1 gap-2`}
              >
                <NavLink
                  key={`f-link-${idx}`}
                  onClick={navLinkClickHandler(idx)}
                  to={link.to}
                  className={({ isActive }) =>
                    `${!getPasosHabilitados().includes(link.name)
                      ? "text-slate-300 hover:cursor-not-allowed hover:text-slate-300"
                      : ""
                    } flex flex-col gap-2 ${!!isActive ? "font-bold" : ""}`
                  }
                >
                  <div className="flex items-center">
                    <div
                      className={`h-5 w-5 ${currentStep === link.name
                        ? "bg-yellow-500"
                        : idx < currenStepIndex
                          ? "bg-sky-500"
                          : "bg-slate-300"
                        } rounded-full`}
                    ></div>
                    {idx + 1 !== generateLinks().length && (
                      <div
                        className={`${currentStep === link.name
                          ? "bg-yellow-500"
                          : idx < currenStepIndex
                            ? "bg-sky-500"
                            : "bg-slate-300"
                          } h-0.5 flex-1`}
                      ></div>
                    )}
                  </div>
                  <div>
                    <span className="capitalize text-sm">{link.label}</span>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        )}
        {!!formulario_loading && <div>Cargando...</div>}
      </div>
      {/* BODY */}
      <div>
        {!formulario_loading && (
          <div className="flex flex-col gap-4 mt-4 px-8">
            <DatosPuntajes
              formulario={formulario}
              mostrarPuntajeCalibracion={getMostrarPuntajeCalibracion()}
              puntajeEvaluadorKey={getPuntajeEvaluadorKey()}
            ></DatosPuntajes>
            {/*  */}
            <div className="flex gap-3">
              {!downloadLoading && (
                <button
                  onClick={downloadPDF}
                  className="bg-sky-500 rounded-md text-white px-2 py-2 flex items-center gap-2"
                >
                  <FileEarmarkArrowDownFill size={18} />
                  <span className="font-semibold">Exportar PDF</span>
                </button>
              )}
              {!!formulario.link_politica && (
                <a
                  href={formulario.link_politica}
                  target="_blank"
                  className="bg-sky-500 rounded-md text-white px-2 py-2 flex items-center gap-2"
                >
                  <FileEarmarkPptFill size={18} />
                  <span className="font-semibold">Descargar política</span>
                </a>
              )}

              {!!downloadLoading && (
                <button className="bg-sky-500 rounded-md text-white p-2 animate-pulse">
                  Generando informe...
                </button>
              )}
            </div>
            {/*  */}
            <EvaluacionTips
              frecuencias={
                evaluacion.puntuaciones_observacion?.length > 0
                  ? evaluacion.puntuaciones_observacion
                  : puntuacionesDefault
              }
              antiguedad={evaluacion.antiguedad_minima}
            ></EvaluacionTips>
          </div>
        )}
        {!!formulario_loading && (
          <div className="flex justify-around gap-4 mt-4 px-8 h-72 animate-pulse">
            <div className="bg-slate-200 rounded-md h-full flex-1"></div>
            <div className="flex flex-1 gap-4">
              <div className="flex-1 bg-slate-200 rounded-md h-full"></div>
              <div className="flex-1 bg-slate-200 rounded-md h-full"></div>
            </div>
          </div>
        )}
        {/*  */}
        <FormularioRoutes></FormularioRoutes>
      </div>
    </div>
  );
}
export default FormularioPage;
