import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/lider";

export const evaluacionApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    evaluaciones: async ({ evaluacionesFilter }) => {
      //   const { nombre = "" } = evaluacionesFilter;
      let nombre = "";
      try {
        const evaluaciones = await axios.get(
          `${URL_BASE}/evaluacion/evaluaciones/?search=${nombre}`,
          {
            headers,
          }
        );
        console.log("evaluacion list api", evaluaciones);
        return evaluaciones.data;
      } catch (error) {
        throw console.error();
      }
    },
    evaluacion: async (params) => {
      const { id } = params;
      try {
        const evaluacion = await axios.get(
          `${URL_BASE}/evaluacion/evaluacion/${id}`,
          {
            headers,
          }
        );
        console.log("evaluacion detail api", evaluacion);
        return evaluacion.data;
      } catch (error) {
        throw console.error();
      }
    },
    //
    evaluacionesLider: async (evaluacionesFilter) => {
      const { id, nombre = "", ordering } = evaluacionesFilter;
      let searchURL = "";
      if (ordering) {
        searchURL = searchURL + `&ordering=${ordering}`;
      }
      try {
        const evaluaciones = await axios.get(
          `${URL_BASE}/evaluacion/evaluacion/${id}/evaluaciones?search=${nombre}${searchURL}`,
          {
            headers,
          }
        );
        console.log("evaluacion list api", evaluaciones);
        return evaluaciones.data;
      } catch (error) {
        throw console.error();
      }
    },
    calibracionesLider: async (evaluacionesFilter) => {
      const { id, nombre = "", ordering } = evaluacionesFilter;
      let searchURL = "";
      if (ordering) {
        searchURL = searchURL + `&ordering=${ordering}`;
      }
      try {
        const evaluaciones = await axios.get(
          `${URL_BASE}/evaluacion/evaluacion/${id}/calibraciones?search=${nombre}${searchURL}`,
          {
            headers,
          }
        );
        console.log("evaluacion list api", evaluaciones);
        return evaluaciones.data;
      } catch (error) {
        throw console.error();
      }
    },
    devolucionesLider: async (evaluacionesFilter) => {
      const { id, nombre = "", ordering } = evaluacionesFilter;
      let searchURL = "";
      if (ordering) {
        searchURL = searchURL + `&ordering=${ordering}`;
      }
      try {
        const evaluaciones = await axios.get(
          `${URL_BASE}/evaluacion/evaluacion/${id}/devoluciones?search=${nombre}${searchURL}`,
          {
            headers,
          }
        );
        console.log("evaluacion list api", evaluaciones);
        return evaluaciones.data;
      } catch (error) {
        throw console.error();
      }
    },
    comentariosLider: async (evaluacionesFilter) => {
      const { id, nombre = "", ordering } = evaluacionesFilter;
      let searchURL = "";
      if (ordering) {
        searchURL = searchURL + `&ordering=${ordering}`;
      }
      try {
        const evaluaciones = await axios.get(
          `${URL_BASE}/evaluacion/evaluacion/${id}/comentarios?search=${nombre}${searchURL}`,
          {
            headers,
          }
        );
        console.log("evaluacion list api", evaluaciones);
        return evaluaciones.data;
      } catch (error) {
        throw console.error();
      }
    },
    //
    formularioDetalle: async (params) => {
      const { id, formularioId } = params;
      try {
        const formulario = await axios.get(
          `${URL_BASE}/evaluacion/evaluacion/${id}/formulario/${formularioId}/detail`,
          {
            headers,
          }
        );
        console.log("formularioDetalle detail api", formulario);
        return formulario.data;
      } catch (error) {
        throw console.error();
      }
    },
    //
    evaluar: async (params) => {
      const { id, formularioId, data } = params;
      try {
        const response = await axios.put(
          `${URL_BASE}/evaluacion/evaluacion/${id}/formulario/${formularioId}/evaluar`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    calibrar: async (params) => {
      const { id, formularioId, data } = params;
      try {
        const response = await axios.put(
          `${URL_BASE}/evaluacion/evaluacion/${id}/formulario/${formularioId}/calibrar`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    devolver: async (params) => {
      const { id, formularioId, data } = params;
      try {
        const response = await axios.put(
          `${URL_BASE}/evaluacion/evaluacion/${id}/formulario/${formularioId}/devolver`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    comentar: async (params) => {
      const { id, formularioId, data } = params;
      try {
        const response = await axios.put(
          `${URL_BASE}/evaluacion/evaluacion/${id}/formulario/${formularioId}/comentar`,
          { ...data },
          {
            headers,
          }
        );
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    //
    informeColaborador: async ({ id, colaboradorId }) => {
      try {
        const response = await axios.get(
          URL_BASE +
          `/evaluacion/evaluacion/${id}/colaborador/${colaboradorId}/informe`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        console.log(JSON.stringify(error));
        throw error;
      }
    },
    getNineboxDataDetail: async (params) => {
      const { evaluacion_id } = params;
      const url = `${URL_BASE}/evaluacion/${evaluacion_id}/ninebox/detail-data`;
      const response = await axios.get(url, { headers });
      return response.data;
    },
    getNineboxDataIndividual: async (params) => {
      const { evaluacion_id, colaborador_id } = params;
      const url = `${URL_BASE}/colaborador/${colaborador_id}/evaluacion/${evaluacion_id}/ninebox/detail`;
      const response = await axios.get(url, { headers });
      return response.data;
    },
  };
};
