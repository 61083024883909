import React, { useEffect, useState } from "react";
import { ArrowRight } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bellIcon from "src/icons/bell.png";
import Header from "src/modules/app/components/Header";
import BarChart from "src/modules/common/components/BarChart";
import { Card } from "src/modules/common/components/Card";
import { CompetenciaCollapsable } from "src/modules/common/components/CompetenciaCollapsable";
import PercentageCircular from "src/modules/common/components/PercentageCircular";
import { ProfileImage } from "src/modules/common/components/ProfileImage";
import { ProfileInfoCard } from "src/modules/common/components/ProfileInfoCard";
import { inicioActions } from "src/modules/inicio/handlers/redux";
import { KpiInfo } from "src/modules/kpi/components/KpiInfo";
import { ReactComponent as EvaluationIcon } from "src/icons/evaluation.svg";
import { getUserFromCookie } from "src/modules/common/handler/helpers";
import { authActions } from "src/modules/auth/handlers/redux";
import { formatNumber } from "src/utils/helpers";
import dayjs from "dayjs";
import { colaboradorActions } from "src/modules/colaborador/handlers/redux";

function InicioPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userFromCookie = getUserFromCookie();

  const empresa = useSelector((state) => state.colaborador.empresa);
  const inicioData = useSelector((state) => state.inicio.inicios) || {};

  const [empresaActual, setEmpresaActual] = useState(null);

  useEffect(() => {
    dispatch(inicioActions.inicios({}));
    dispatch(colaboradorActions.getEmpresa({}));
    dispatch(authActions.setUser({ user: userFromCookie }));
  }, []);

  useEffect(() => {
    setEmpresaActual(empresa)
  }, [empresa])

  const {
    competencias_generales = {},
    competencias_cargo = {},
    competenias_area = {},
    kpis_asignados: kpisAsignados = {},
    promedios_evaluaciones = [],
    promedio_evaluaciones = 0,
    evaluaciones_en_curso = {},
    desempeno_equipo = {},
    salario = {},
  } = inicioData;
  const { evaluaciones_en_curso: evaluacionesEnCurso = [] } =
    evaluaciones_en_curso;
  const { colaboradores: subordinadosDesempeno = [] } = desempeno_equipo;
  //
  const EvaluacionCursoItem = ({ evaluacion }) => {
    const {
      id,
      nombre = "",
      dias_finalizar = 0,
      tareas_pendientes = 0,
      tareas_totales = 0,
      porcentaje_tareas = 0,
    } = evaluacion;
    //
    const goToEvaluacion = (evaluacionId) => () => {
      navigate(`/evaluaciones/${evaluacionId}`);
    };
    //
    return (
      <div className="border rounded-md p-3 text-sm flex flex-col gap-3">
        {/* header */}
        <div className="flex justify-between">
          <div className="flex gap-2">
            <div className="bg-sky-500 w-10 h-10 rounded-md flex items-center justify-center">
              <EvaluationIcon></EvaluationIcon>
            </div>
            <div className="flex flex-col">
              <div className="text-slate-500">Evaluacion de desempeño:</div>
              <div className="font-bold">{`${nombre}`}</div>
            </div>
          </div>
          <button
            onClick={goToEvaluacion(id)}
            className="bg-sky-300 w-6 h-6 rounded-sm flex justify-center items-center"
          >
            <ArrowRight color={"blue"}></ArrowRight>
          </button>
        </div>
        {/* body */}
        <div className="flex flex-col gap-1">
          <div className="flex justify-between text-slate-500">
            <div>{`(#) Tareas pendientes: ${tareas_totales - tareas_pendientes // tareas hechas
              }/${tareas_totales}`}</div>
            <div>{`${porcentaje_tareas}%`}</div>
          </div>
          <div>
            <div className="bg-slate-400 h-1 w-full rounded-md">
              <div
                style={{ width: `${porcentaje_tareas}%` }}
                className="bg-black h-1 rounded-sm"
              ></div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="text-slate-500">
          <div>Finaliza en: {`${dias_finalizar} días`}</div>
        </div>
      </div>
    );
  };
  //
  const getHistorialBarChartData = () => {
    let data = [];
    if (promedios_evaluaciones.length) {
      let promediosEvaluacionesCopy = [...promedios_evaluaciones];
      // Ordenar por id en orden descendente
      promediosEvaluacionesCopy = promediosEvaluacionesCopy.sort(
        (a, b) => b.id - a.id
      );
      // Tomar los primeros 6 elementos
      promediosEvaluacionesCopy = promediosEvaluacionesCopy.slice(0, 6);
      data = promediosEvaluacionesCopy.map((p, idx) => ({
        x: idx + 1,
        y: p.puntaje,
        name: p.nombre,
      }));
    }
    console.log("getHistorialBarChartData", data);
    return data;
  };
  const toEquipo = () => {
    navigate("/equipo/");
  };
  const toColaboradores = () => {
    navigate("/colaboradores/");
  };

  //
  return (
    <>
      <Header
        titulo={`Hola, ${inicioData.lider?.nombre || ""} ${inicioData.lider?.apellido || ""
          }`}
        subtitulo={"Esta es tu plataforma de crecimiento organizacional"}
        className={`pb-3`}
        RightComponent={() => {
          return (
            <div className="flex-grow flex flex-row justify-end gap-3">
              <div className="bg-white h-12 w-12 flex items-center justify-center border rounded-md">
                <img src={bellIcon} alt="Bell icon" />
              </div>
            </div>
          );
        }}
      ></Header>
      <div className="flex flex-col gap-4 py-4 px-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 flex-wrap gap-8 ">
          {/* foto y clima */}
          <div className={`flex flex-col h-56 gap-2`}>
            <div className="flex flex-row justify-between">
              {!inicioData.lider?.foto_empleado && (
                <ProfileImage className="h-36 w-36 p-8"></ProfileImage>
              )}

              {!!inicioData.lider?.foto_empleado && (
                <div className="w-36 bg-slate-200 rounded-md">
                  <img
                    className="object-cover h-36 w-36 rounded-md"
                    src={inicioData.lider?.foto_empleado}
                    alt={`Foto Lider`}
                  />
                </div>
              )}
            </div>
            {!!inicioData.lider?.jefe_directo && (
              <div className="flex flex-col">
                <div className="font-bold">Jefe directo asignado:</div>
                <div className="flex flex-row gap-2  items-center">
                  {!!inicioData.lider?.jefe_directo &&
                    !inicioData.lider?.jefe_directo?.foto_empleado && (
                      <ProfileImage className="h-12 w-12 p-2"></ProfileImage>
                    )}

                  {!!inicioData.lider?.jefe_directo.foto_empleado && (
                    <div className="w-12 bg-slate-200 rounded-md">
                      <img
                        className="object-cover h-12 w-12 rounded-md"
                        src={inicioData.lider?.jefe_directo.foto_empleado}
                        alt={`Foto Jefe de Lider`}
                      />
                    </div>
                  )}
                  <div className="flex flex-col flex-grow text-zinc-700">
                    <div className="text-sm">
                      {`${inicioData.lider?.jefe_directo?.nombre} ${inicioData.lider?.jefe_directo?.apellido}`}
                    </div>
                    <div className="text-sm font-bold">{`${inicioData.lider?.jefe_directo?.cargo?.nombre ||
                      "Sin especificar"
                      }`}</div>
                  </div>
                </div>
              </div>
            )}
            {!inicioData.lider?.jefe_directo && (
              <div className="p-2 font-semibold">Sin jefe asignado</div>
            )}
          </div>
          {/*  colaboradores */}
          <Card>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row justify-between gap-4">
                <div className="text-sm font-bold">
                  Colaboradores dentro de la organizacion
                </div>
                <div className="flex justify-end">
                  <div
                    className="bg-sky-200 h-8 w-8 rounded-md hover:cursor-pointer"
                    onClick={toColaboradores}
                  >
                    <ArrowRight color={"blue"} className="m-2"></ArrowRight>
                  </div>
                </div>
              </div>
              <div className="font-bold text-6xl">
                {inicioData.colaboradores_total}
              </div>
              <div className="text-sm">Nuevos ingresos:</div>
              <div className="flex flex-row gap-2">
                {inicioData.colaboradores_nuevos?.length > 0 &&
                  inicioData.colaboradores_nuevos
                    .slice(0, 3)
                    .map((nuevo, idx) => (
                      <>
                        {!nuevo.foto_empleado && (
                          <ProfileImage
                            key={`fc-${idx}`}
                            className="h-12 w-12 p-2"
                            title={`${nuevo.nombre} ${nuevo.apellido}`}
                          ></ProfileImage>
                        )}
                        {!!nuevo.foto_empleado && (
                          <div
                            key={`fc-${idx}`}
                            className="w-12 bg-slate-200 rounded-md"
                          >
                            <img
                              className="object-cover h-12 w-12 rounded-md"
                              src={nuevo.foto_empleado}
                              alt={`Foto Colaborador`}
                              title={`${nuevo.nombre} ${nuevo.apellido}`}
                            />
                          </div>
                        )}
                      </>
                    ))}
                {inicioData.colaboradores_nuevos?.length < 1 && (
                  <div className="">No existen registros</div>
                )}
              </div>
            </div>
          </Card>
          {/* equipo */}
          <Card>
            <div className="flex flex-row justify-between gap-4">
              <div className="text-sm font-bold">
                Colaboradores dentro de mi equipo
              </div>
              <div className="flex justify-end">
                <div
                  className="bg-sky-200 h-8 w-8 rounded-md hover:cursor-pointer"
                  onClick={toEquipo}
                >
                  <ArrowRight color={"blue"} className="m-2"></ArrowRight>
                </div>
              </div>
            </div>
            <div className="font-bold text-6xl">
              {inicioData.subordinados_total}
            </div>
            <div className="text-sm">Nuevos ingresos:</div>
            <div className="flex flex-row gap-2">
              {inicioData.subordinados_nuevos?.length > 0 &&
                inicioData.colaboradores_nuevos
                  .slice(0, 3)
                  .map((nuevo, idx) => (
                    <>
                      {!nuevo.foto_empleado && (
                        <ProfileImage
                          key={`np-${idx}`}
                          className="h-12 w-12 p-2"
                          title={`${nuevo.nombre} ${nuevo.apellido}`}
                        ></ProfileImage>
                      )}
                      {!!nuevo.foto_empleado && (
                        <div
                          key={`np-${idx}`}
                          className="w-12 bg-slate-200 rounded-md"
                        >
                          <img
                            className="object-cover h-12 w-12 rounded-md"
                            src={nuevo.foto_empleado}
                            alt={`Foto Colaborador`}
                            title={`${nuevo.nombre} ${nuevo.apellido}`}
                          />
                        </div>
                      )}
                    </>
                  ))}
              {inicioData.subordinados_nuevos?.length < 1 && (
                <div className="">No existen registros</div>
              )}
            </div>
          </Card>
          {/* estas en el area */}
          <Card>
            <div className="flex flex-row justify-between gap-4">
              <div className="text-sm font-bold">Estás en el área</div>
            </div>
            <div className="font-bold text-2xl my-4">
              {inicioData.lider?.area?.nombre || "Sin asignar"}
            </div>
            <div className="text-sm">Colaboradores en el área:</div>
            <div className="flex flex-row gap-1">
              {inicioData.colaboradores_area?.length > 0 &&
                inicioData.colaboradores_area.slice(0, 3).map((nuevo, idx) => (
                  <>
                    {!nuevo.foto_empleado && (
                      <ProfileImage
                        className="h-12 w-12 p-2"
                        key={`ca-${idx}`}
                      ></ProfileImage>
                    )}
                    {!!nuevo.foto_empleado && (
                      <div
                        className="w-12 bg-slate-200 rounded-md"
                        key={`ca-${idx}`}
                      >
                        <img
                          className="object-cover h-12 w-12 rounded-md"
                          src={nuevo.foto_empleado}
                          alt={`Foto Colaborador`}
                          title={`${nuevo.nombre} ${nuevo.apellido}`}
                        />
                      </div>
                    )}
                  </>
                ))}
              {inicioData.colaboradores_area?.length < 1 && (
                <div className="">No existen registros</div>
              )}
            </div>
          </Card>
        </div>
        {/* INFORME GENERAL DE DESEMPEÑO */}
        <>
          <div className="mt-4">
            <div className="font-bold text-lg">
              Informe general de desempeño
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            {/* historia */}
            <Card className={`col-span-1 md:col-span-8`}>
              <div className="font-bold">Historial de evaluaciones</div>
              <div className="flex max-h-60">
                <BarChart
                  data={getHistorialBarChartData()}
                  domainPadding={{ x: 35, y: [0, 0] }}
                  usingXLabels={false}
                  tickLabelsFontSize={14}
                ></BarChart>
              </div>
            </Card>
            {/* Promedio desempeño */}
            <Card className={`col-span-1 md:col-span-4`}>
              <div className="flex flex-col justify-between flex-1">
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <div className="font-bold">Promedio desempeño</div>
                  </div>
                </div>
                <div className="flex justify-center items-center h-full">
                  <PercentageCircular
                    width={180}
                    height={180}
                    strokeWidth={9}
                    strokeColor={"red"}
                    value={promedio_evaluaciones || 0}
                    content={
                      <>
                        <div className="font-bold text-2xl">
                          {promedio_evaluaciones?.toFixed(2) || 0}%
                        </div>
                      </>
                    }
                  ></PercentageCircular>
                </div>
              </div>
            </Card>
          </div>
        </>
      </div>
      {/* EVALUACIONES EN CURSO */}
      {evaluacionesEnCurso.length > 0 && (
        <>
          <div className="font-bold text-lg px-12 mb-3 mt-4">
            Evaluaciones en curso:
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-12">
            {evaluacionesEnCurso.map((evaluacion, idx) => (
              <div className="col-span-1" key={`eec-${idx}`}>
                <EvaluacionCursoItem
                  evaluacion={evaluacion}
                ></EvaluacionCursoItem>
              </div>
            ))}
          </div>
        </>
      )}
      {/* PROMEDIO DESEMPEÑO PERSONAS A TU CARGO */}
      {subordinadosDesempeno.length > 0 && (
        <div className="px-12 mt-4">
          <div className="font-bold text-lg mb-3">
            Promedio de desempeño de personas a tu cargo
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-3">
            <div className="col-span-1 md:col-span-3">
              <Card className={`col-span-1 md:col-span-4 h-full`}>
                <div className="flex flex-col justify-between flex-1">
                  <div className="flex flex-row justify-between items-center">
                    <div>
                      <div className="text-sm font-bold">
                        Promedio desempeño de mi equipo
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center h-full">
                    <PercentageCircular
                      width={180}
                      height={180}
                      strokeWidth={9}
                      strokeColor={"red"}
                      value={desempeno_equipo.promedio_equipo || 0}
                      content={
                        <>
                          <div className="font-bold text-2xl">
                            {desempeno_equipo.promedio_equipo?.toFixed(2) || 0}%
                          </div>
                        </>
                      }
                    ></PercentageCircular>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-span-1 md:col-span-9 border-4x border-blue-500x">
              <div className="grid grid-cols-1 md:grid-cols-9 gap-4 border-2x border-green-500x">
                {subordinadosDesempeno.map((colaborador, idx) => (
                  <div className="col-span-1 md:col-span-3" key={`sd-${idx}`}>
                    <div className="border rounded-md p-2">
                      <ProfileInfoCard
                        key={"col-" + idx}
                        image={colaborador.foto_empleado}
                        name={`${colaborador.nombre} ${colaborador.apellido}`}
                      >
                        <div className="text-sm">{`Promedio: ${(
                          colaborador.promedio + ""
                        ).replace(".", ",")}%`}</div>
                      </ProfileInfoCard>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* COMPETENCIAS */}
      <div className="flex flex-col gap-3 py-4 px-12">
        {/* Generales */}
        {competencias_generales.length > 0 && (
          <div className="flex flex-col gap-3">
            <h5 className="text-lg m-0 font-bold">
              Competencias generales de la empresa
            </h5>
            {competencias_generales.map((competencia, idx) => (
              <CompetenciaCollapsable
                key={"cg-" + competencia.id}
                competencia={competencia}
                editable={false}
                showInput={!!competencia.peso}
                idx={idx}
              />
            ))}
          </div>
        )}
        {/* Cargo */}
        {competencias_cargo.length > 0 && (
          <div className="flex flex-col gap-3 mt-2">
            <h5 className="text-md m-0 font-bold">
              Competencias específicas de tu cargo
            </h5>
            {competencias_cargo.map((competenciaCargo, index) =>
              competenciaCargo.competencias.map((competencia, idx) => (
                <CompetenciaCollapsable
                  key={"cc-" + competencia.id}
                  competencia={competencia}
                  editable={false}
                  showInput={!!competencia.peso}
                  idx={idx}
                />
              ))
            )}
          </div>
        )}
        {/* Area */}
        {competenias_area.length > 0 && (
          <div className="flex flex-col gap-3 mt-2">
            <h5 className="text-md m-0 font-bold">
              Competencias específicas de tu área
            </h5>
            {competenias_area.map((competenciaArea, index) =>
              competenciaArea.competencias.map((competencia, idx) => (
                <CompetenciaCollapsable
                  key={"ca-" + competencia.id}
                  competencia={competencia}
                  editable={false}
                  showInput={!!competencia.peso}
                  idx={idx}
                />
              ))
            )}
          </div>
        )}
      </div>
      {/* KPIS */}
      {empresaActual?.permiso_kpi && (
        <div className="px-12">
          <Card bgColor="bg-zinc-100" className="gap-4 p-4 bg-blue-4">
            <div className="grid grid-cols-3 gap-3">
              <div className="flex flex-row gap-2  items-center">
                {!inicioData.lider?.foto_empleado && (
                  <div className="w-12 h-12 bg-zinc-200 rounded-md"></div>
                )}
                {!!inicioData.lider?.foto_empleado && (
                  <div className="w-312 bg-slate-200 rounded-md">
                    <img
                      className="object-cover h-12 w-12 rounded-md"
                      src={inicioData.lider?.foto_empleado}
                      alt={`Foto Lider`}
                    />
                  </div>
                )}
                <div className="flex flex-col flex-grow text-zinc-700">
                  <div className="text-sm font-bold">KPIs Asignados</div>
                  <div className="text-sm">
                    {kpisAsignados.length} KPIs vinculados
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              {kpisAsignados.length > 0 &&
                kpisAsignados.map((kpi, idx) => (
                  <Card className="gap-3 p-4" key={`kpia-${idx}`}>
                    <KpiInfo kpi={kpi}></KpiInfo>
                  </Card>
                ))}
            </div>
          </Card>
        </div>
      )}
      {/* ESCALA */}
      <div className="px-12 py-4 grid grid-cols-1 md:grid-cols-12">
        <div className="col-span-4">
          <div className="w-full flex flex-col gap-3 bg-white border border-slate-100 rounded-md p-4">
            <h3 className="font-semibold">Salario actual</h3>
            <div className="py-4 font-medium text-center flex flex-wrap w-full justify-center items-end gap-1">
              <span className="text-2xl">Gs.</span>
              <span className="text-4xl">
                {formatNumber(salario?.salario_fijo ?? 0)}
              </span>
            </div>
            <div className="text-sm">
              <div>Última ajuste salarial:</div>
              <div>
                {!!salario?.ultima_modificacion_salario
                  ? dayjs(
                    salario?.ultima_modificacion_salario?.slice(0, 10)
                  ).format("DD/MM/YYYY")
                  : "Sin registro"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InicioPage;
